<template>
  <card class="main-content">
    <div class="mb-3">
      <div class="card-header"></div>

      <!-- search -->
      <div class="row">
        <div class="ml-auto py-2 pr-4">
          <input
            id="search-box"
            type="text"
            class="search-box"
            name="q"
            v-model="search"
          />
          <label for="search-box">
            <span class="fas fa-search search-icon"></span>
          </label>
        </div>
      </div>
      <!-- search -->
      <div class="card-body">
        <!-- Loading spinner -->
        <div v-if="isLoading" class="text-center">
          <div class="spinner-border">
              <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div v-if="!isLoading" class="table-responsive">
          <!-- content -->
          <story-table
            type="hover table-bordered"
            :adminId="adminId"
            :accountId="accountId"
            :allScenario="allScenario"
            :userIconList="userIconList"
            :data="table.data"
            :columns="table.columns"
            :trigger="exsitTrigger"
            v-bind:currentNumber="allScenario.length"
          ></story-table>
          <!-- content -->
          <pagination
            v-model="page"
            :per-page="rowPerPage"
            :records="allScenario.length"
            :options="{
              texts: {
    	          count: this.$t('__paginationText')
              }
            }"
            @paginate="callback"
          ></pagination>
        </div>
      </div>
    </div>
  </card>
</template>
<script>
import { apiFunction } from "./Axios/functions";
import { StoryTable } from "./Components/index";

export default {
  mounted: function () {},
  components: {
    StoryTable,
  },
  data() {
    const tableData = [];

    return {
      adminId: "",
      jwtToken: "",
      accountId: "",
      isLoading: false,
      search: "",
      page: 1,
      rowPerPage: 20,
      allScenario: [],
      userIconList: [],
      exsitTrigger: {
        error: false,
        follow: false,
      },
      table: {
        data: [...tableData],
      },
    };
  },
  watch: {
    search: {
      handler: function (val, oldval) {
        let reg = new RegExp(val);
        let searchResult = [];

        $.each(this.allScenario, (i, scenario) => {
          if (
            reg.test(this.$t('__Keyword')) == true &&
            scenario.scenario.trigger == "keyword" &&
            reg.test(scenario.scenario.scenarioName) == false
          ) {
            searchResult.push(scenario);
          }

          if (
            reg.test(this.$t("__Userjoin")) == true &&
            scenario.scenario.trigger == "follow" &&
            reg.test(scenario.scenario.scenarioName) == false
          ) {
            searchResult.push(scenario);
          }

          if (
            reg.test(scenario.scenario.scenarioName) == true ||
            reg.test(scenario.scenario.trigger) == true
          ) {
            searchResult.push(scenario);
          }
        });
        this.table.data = searchResult;
      },
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    // Prepare page content
    async loadData() {
      // Getting required id with route parameter
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(",");
        this.adminId = decrypt[0];
        this.accountId = decrypt[1];
      }
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser();
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken;
      } catch (e) {
        this.$fire({
          title: this.$t('__authExpired'),
          text: this.$t('__pleaseLogin'),
          type: "error"
        }).then(() => {
          this.$Amplify.Auth.signOut();
          localStorage.clear();
          this.$router.push("/login");
        });
      }

      let formData = { adminId: this.adminId, accountId: this.accountId };
      this.isLoading = true;
      console.log('story getScenario');
      apiFunction.getScenario(formData, this.jwtToken).then((data) => {
        this.isLoading = false;
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: "error"
          }).then(() => {
            this.$Amplify.Auth.signOut();
            localStorage.clear();
            this.$router.push("/login");
          });
        } else {
          this.table.data = this.sortByUpdateTime(data.data.Result.data);
          this.allScenario = this.sortByUpdateTime(data.data.Result.data);
          this.table.data.forEach((scenario) => {
            if (scenario.scenario.trigger == "error") {
              this.exsitTrigger.error = true;
            }

            if (scenario.scenario.trigger == "follow") {
              this.exsitTrigger.follow = true;
            }
          });
          // init pagination
          this.callback(this.page);
        }
      });

      apiFunction.getIconUrl(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          }).then(() => {
            this.$Amplify.Auth.signOut();
            localStorage.clear();
            this.$router.push("/login");
          });
        } else {
          if(data.data == null || data.data == '') return
          this.userIconList = data.data.Result.data;
        }
      });
    },
    // Pagination
    callback(pageNum) {
      let arr = this.allScenario.slice(
        this.rowPerPage * (pageNum - 1),
        this.rowPerPage * pageNum
      );
      this.table.data = arr;
    },
    // Sort table row by update time
    sortByUpdateTime(array) {
      array.sort(function (a, b) {
        var dateA = new Date(a.updateTime),
          dateB = new Date(b.updateTime);
        return dateA < dateB ? 1 : -1;
      });
      return array;
    },
  },
};
</script>
<style>
</style>
